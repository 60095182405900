import { Box, Button, ButtonBase, Grid, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { ContainerHelmChartName } from '../controls/CreateContainer';
import { MarketplaceCardData } from '../marketplace-screen/MarketplaceExampleCards';

export interface MarketplaceCardProps extends MarketplaceCardData {
  handleClick: (helmChartName?: ContainerHelmChartName) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      padding: theme.spacing(2),
      margin: 'auto',
      maxWidth: 240,
      minHeight: 300,
      borderColor: theme.palette.grey[700],
      borderRadius: '.5rem',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    image: {
      width: 80,
      height: 40,
    },
    titleText: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    text: {
      color: theme.palette.text.primary,
    },
    typeText: {
      color: theme.palette.grey[500],
    },
    typeGrid: {
      margin: theme.spacing(2, 0, 0),
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '90%',
      maxHeight: '90%',
    },
    imageCircle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      width: '4rem',
      height: '4rem',
      backgroundColor: 'white',
    },
    installButton: {
      marginLeft: '.5rem',
    },
    hover: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

const text = {
  type: 'Type',
};

const MarketplaceCard: FunctionComponent<MarketplaceCardProps> = (props) => {
  const classes = useStyles();

  return (
    <Box flex={1}>
      <Box className={classes.box}>
        <Grid
          container
          spacing={2}
          className={classes.hover}
          onClick={() => {
            props.handleClick(props.helmChartName);
          }}
        >
          <Grid item xs={12} id={'logo'}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
            >
              <div className={classes.imageCircle}>
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    alt="logo unavailable"
                    src={props.logo}
                  />
                </ButtonBase>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} id={'title'}>
            <Typography variant={'body1'} className={classes.titleText}>
              {props.title}
            </Typography>
            <Typography variant={'body2'} className={classes.text}>
              {props.subtitle}
            </Typography>
          </Grid>
          <Grid item xs={12} id={'description'}>
            <Typography variant={'body2'} className={classes.text}>
              {props.description}
            </Typography>
          </Grid>
          {props.helmChartName && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.installButton}
            >
              Install
            </Button>
          )}
          <Grid item xs={12} id={'type'} className={classes.typeGrid}>
            <Typography variant={'body2'} className={classes.typeText}>
              {text.type}: {props.type}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MarketplaceCard;
