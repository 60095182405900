import { Box, Dialog, DialogContent, Grid } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import React, { FunctionComponent } from 'react';
import defaultUseMarketplacePage from '../../hooks/useMarketplacePage';
import {
  ContainerHelmChartName,
  CreateContainer,
} from '../controls/CreateContainer';
import MarketplaceCard from '../marketplace-card/MarketplaceCard';
import { MarketplaceCardData } from './MarketplaceExampleCards';

interface MarketplaceScreenProps {
  cards: MarketplaceCardData[];
  useMarketplacePage: typeof defaultUseMarketplacePage;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: theme.spacing(1),
    },
    mainGrid: {
      padding: theme.spacing(0, 2, 0, 2),
    },
    logo: {
      margin: theme.spacing(0, 1, 2),
    },
    titlePadding: {},
    pageToolbar: {
      display: 'flex',
      height: '3.5rem',
      padding: '0 1.65rem .5rem 1rem',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: theme.palette.text.primary,
    },
    pageHeader: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      fontWeight: 300,
      fontSize: '1.25rem',
    },
    pageTitle: {
      fontFamily: 'Futura, Tw Cen MT',
    },
  })
);

const text = {
  title: 'Marketplace',
};

const MarketplaceScreen: FunctionComponent<MarketplaceScreenProps> = ({
  cards,
  useMarketplacePage,
}) => {
  const classes = useStyles();
  const {
    createContainerOpen,
    handleCreateContainerOpen,
    handleCreateContainerClose,
    handleCreateContainerChange,
    helmChartName,
  } = useMarketplacePage();

  const handleCardClick = (helmChartName?: ContainerHelmChartName) => {
    if (helmChartName) {
      handleCreateContainerOpen(helmChartName);
    }
  };

  return (
    <Box flex={1}>
      <Box className={classes.pageToolbar}>
        <div className={classes.pageHeader}>
          <ShoppingCartIcon />
          <div className={classes.pageTitle}>{text.title}</div>
        </div>
      </Box>
      <Grid container direction={'row'} className={classes.mainGrid}>
        {cards?.map((card, index) => (
          <Grid className={classes.grid} key={index}>
            <MarketplaceCard {...card} handleClick={handleCardClick} />
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={createContainerOpen}
        onClose={handleCreateContainerClose}
        aria-labelledby="create-container-dialog"
      >
        <DialogContent>
          <CreateContainer
            onCancel={handleCreateContainerClose}
            onCreate={handleCreateContainerChange}
            selectedHelmChart={helmChartName}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MarketplaceScreen;
